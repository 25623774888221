<template>
  <v-responsive>
    <SubMenuLottowinMobile v-if="$vuetify.breakpoint.smAndDown"></SubMenuLottowinMobile>
    <v-container :fluid="$vuetify.breakpoint.lgAndDown" style="max-width:1440px;"> 
      <v-row>
        <v-col cols="12" sm="3" flat tile v-if="$vuetify.breakpoint.mdAndUp">
          <v-container class="pa-0">
            <v-card class="d-flex justify-space-between" flat tile>
              <v-card class="pa-0 pl-6" flat tile>
                <h2
                  class="
                    bx_pagetitle
                    float-left
                    mt-5
                    mb-3
                    blue--text
                    text--darken-4
                  "
                >
                  {{ breadcrumbs[1].text }}
                </h2>
              </v-card>
            </v-card>
          </v-container>
          <v-divider></v-divider>

          <SubMenuLottowin></SubMenuLottowin>


              <v-row class="my-0 mx-0" style="background: #fff">
                <v-col>
                     <GlobalSideBannerBottom></GlobalSideBannerBottom>
                </v-col>
              </v-row>


        </v-col>
        <v-col cols="12" sm="12" md="9" :class="$vuetify.breakpoint.smAndDown? 'mt-0 pt-0':''">
          <v-container class="pa-0" v-if="$vuetify.breakpoint.mdAndUp">
            <v-card class="d-flex justify-space-between" flat tile>
              <v-card class="pa-0" flat tile>
                <h2 class="bx_pagetitle float-left mt-5 mb-3">
                  {{ breadcrumbs[2].text }}
                </h2>
              </v-card>
              <v-card class="pa-0" flat tile v-if="$vuetify.breakpoint.mdAndUp">
                <v-breadcrumbs :items="breadcrumbs" class="float-right mt-2">
                  <template v-slot:divider>
                    <v-icon>mdi-chevron-right</v-icon>
                  </template>
                </v-breadcrumbs>
              </v-card>
            </v-card>
          <v-divider></v-divider>
          </v-container>



          <v-row class="my-3 mx-0" no-gutters style="background: #fff">
            <v-col>
                  <GlobalSubBannerTop></GlobalSubBannerTop>
            </v-col>
          </v-row>




          <v-sheet min-height="53vh">
            <!--  -->



          <v-responsive class="mt-10 mb-5 mx-auto" max-width="450">

        <v-select
        class="mt-2 mb-4"
          :items="latestLottoDataAll"
          item-value="drwNo"
          item-text="drwNo"
          v-model="selectDrw"
          return-object

          outlined
          label="회차 선택"
          dense
          hide-details=""
          style="max-width:160px;margin:0 auto;"
        ></v-select>

        <h2 class="text-center mb-3"><strong style="color:#D5370A;">{{$addCommas(selectDrw.drwNo)}}회</strong> 당첨결과</h2>


              <WidgetLottoNumber
              v-if="selectDrw"
               :number="[selectDrw.drwtNo1,selectDrw.drwtNo2,selectDrw.drwtNo3,selectDrw.drwtNo4,selectDrw.drwtNo5,selectDrw.drwtNo6]" 
               :bonus="selectDrw.bnusNo" style="text-align:center;"></WidgetLottoNumber>
          </v-responsive>







    <v-container class="mt-10">
      <v-row>

        <v-col
          cols="12"
          sm="6"
        >
          <v-text-field
          class="input-black"
            :value="$addCommas(selectDrw.firstWinamnt)"
            label="1명당 총금액"
            outlined
            disabled
          ></v-text-field>
        </v-col>


        <v-col
          cols="12"
          sm="6"
        >
          <v-text-field
          class="input-black"
            :value="$addCommas(selectDrw.firstPrzwnerCo)"
            label="1등 당첨자 수"
            outlined
            disabled
          ></v-text-field>
        </v-col>

        <v-col
          cols="12"
          sm="6"
        >
          <v-text-field
          class="input-black"
            :value="$addCommas(selectDrw.firstAccumamnt)"
            label="1등 당첨 총금액"
            outlined
            disabled
          ></v-text-field>
        </v-col>

        <v-col
          cols="12"
          sm="6"
        >
          <v-text-field
          class="input-black"
            :value="$addCommas(selectDrw.totSellamnt)"
            label="총 판매액"
            outlined
            disabled
          ></v-text-field>
        </v-col>


      </v-row>
    </v-container>
    

              <v-row class="my-3 mx-0" no-gutters style="background: #fff">
                <v-col>
                     <GlobalSubBannerBottom></GlobalSubBannerBottom>
                </v-col>
              </v-row>

          </v-sheet>
        </v-col>
      </v-row>
    </v-container>
  </v-responsive>
</template>

<script>
//import HelloWorld from "../components/HelloWorld";

export default {
  name: "LottoWin",

  components: {
    //HelloWorld,
  },
  data: () => ({
    selectedItem: 0,
    breadcrumbs: [
      { text: "홈" },
      { text: "당첨결과 분석" },
      { text: "회차별 당첨결과" },
      //{ text: "당첨 통계", icon: "mdi-menu-right" },
    ],

      items: [975,975,975, 975],
    lottoNumbers:  
                [16, 18, 19, 27, 29, 40]
      ,

latestLottoDataAll:null,
selectDrw:null,
  }),



  mounted() {
    //페이지 시작하면은 자동 함수 실행
    this.getLatestResult();
  },

  watch: {
    selectedItem() {
      //alert(this.selectedItem);
      //this.createLottoNums2 = [];
      //this.createLottoNums3 = null;

      this.$store.commit("UPDATE_SELECT_NUMBERS", []);
      this.$store.commit("UPDATE_RESULT_NUMBERS", []);
    },
  },
  methods: {

    getLatestResult() {

      this.$axios
        .get(
          "//lotto7777.iwinv.net/lottoapp/?drwNo=all&mode=get&order=desc"
        )
        .then((res) => {
          console.log(res);

          this.latestLottoDataAll = res.data;
          this.selectDrw = this.latestLottoDataAll[0];

/*
          this.lottoNumbers = [
            this.latestLottoData['drwtNo1'],
            this.latestLottoData['drwtNo2'],
            this.latestLottoData['drwtNo3'],
            this.latestLottoData['drwtNo4'],
            this.latestLottoData['drwtNo5'],
            this.latestLottoData['drwtNo6']
            ];

          this.lottoBonus = this.latestLottoData['bnusNo'];
*/


        })
        .then((err) => {
          console.log(err);

          this.loading = false;
        });

    },




  },



};
</script>
<style></style>
